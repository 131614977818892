import JSONParseError from './JSONParseError';

// TODO: Move to network package

/**
 * Utility Functions for the purpose of interacting with the network
 */

// Function to be used in a chain after a fetch() to be able to retrieve
// network errors.
// Reference: https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
const handleNetworkErrors = async (response: any) => {
  if (!response.ok) {
    let body: any;
    const clone = response.clone();
    try {
      body = await response.json();
    } catch (error) {
      body = await clone.text();
      throw new JSONParseError(
        `Unable to parse server response. The server may be unavailable and unable to return a correct response at this time. Please try again later. If the problem persists, please contact Administration.`,
        body
      );
    }
    throw new NetworkError(`Network Error`, response.status, response.statusText, body);
  }
  return response;
};

class NetworkError extends Error {
  public status: number;
  public statusText: string;
  public body: any;

  constructor(message: string, status: number, statusText: string, body: any) {
    super(message);
    this.body = body;
    this.status = status;
    this.statusText = statusText;
  }
}

export { handleNetworkErrors, NetworkError };

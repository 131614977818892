import { observable, action, runInAction } from 'mobx';

import ElementNotPresentError from './errors/ElementNotPresentError';

import { isUndefined } from 'util';

interface IGenericElement {
  id: number;
}

class GenericStore<T extends IGenericElement> {
  @observable public contents: T[] = [];

  @action('Adding an element to the store')
  public add(element: T): void {
    if (!this.exists(element.id)) {
      this.contents.push(element);
    }
  }

  public addAll(elements: T[]): void {
    runInAction('Adding several elements to the store', () => {
      elements.forEach((element) => {
        this.add(element);
      });
    });
  }

  public addOrUpdateAll(elements: T[]): void {
    runInAction('Adding or Updating several elements to the store', () => {
      elements.forEach((element) => {
        this.addOrUpdate(element);
      });
    });
  }

  public get(elementId: number): T | undefined {
    return this.contents.find((element) => element.id === elementId);
  }

  public addOrUpdate(element: T): void {
    if (!this.exists(element.id)) {
      this.add(element);
    } else {
      this.update(element);
    }
  }

  @action('Updating an element in the store')
  public update(element: T): void {
    const index = this.contents.findIndex((e) => e.id === element.id);
    if (index === -1) {
      throw new ElementNotPresentError(`Element with ID: ${element.id} is not present in the store yet`);
    }
    this.contents.splice(index, 1, element);
  }

  @action('Removing an element from the store')
  public remove(elementId: number): void {
    const index = this.contents.findIndex((element) => element.id === elementId);
    if (index === -1) {
      throw new ElementNotPresentError(`Element with ID: ${elementId} is not present in the store yet`);
    }
    this.contents.splice(index, 1);
  }

  @action('Clearing the store contents')
  public clear(): void {
    this.contents = [];
  }

  public exists(elementId: number): boolean {
    return !isUndefined(this.get(elementId));
  }
}

export { ElementNotPresentError };
export default GenericStore;

import ApiService from './ApiService';

import { User, UserPassword } from '../stores/models/models';

class UserService extends ApiService {
  constructor() {
    super();
  }

  public fetchUsers(): Promise<User[]> {
    const url: string = this.getUrl(`/user/`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data !== null && responseJson.data.length !== 0) {
          return this.jsonConvert.deserialize(responseJson.data, User);
        }
        return [];
      });
  }

  public fetchUserById(userId: number): Promise<User> {
    const url: string = this.getUrl(`/user/info/${userId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, User);
      });
  }

  public fetchUnscopedUserById(userId: number): Promise<User> {
    const url: string = this.getUrl(`/user/unscoped/${userId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, User);
      });
  }

  public createOrUpdateUser(user: User): Promise<User> {
    const baseUrl: string = `/user`;
    const isNew: boolean = user.id === 0;
    const url: string = isNew ? this.getUrl(baseUrl) : this.getUrl(`${baseUrl}/info/${user.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const jsonUser = this.jsonConvert.serialize(user);
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonUser)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, User);
      });
  }

  public registerClient(userPassword: UserPassword): Promise<User> {
    const url: string = this.getUrl(`/register/client`);
    const jsonUser = this.jsonConvert.serialize(userPassword);
    return fetch(url, {
      method: 'POST',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonUser)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, User);
      });
  }

  public deleteUser(userId: number): Promise<void> {
    const url: string = this.getUrl(`/user/delete/${userId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }

  public deleteUserGdpr(userId: number): Promise<void> {
    const url: string = this.getUrl(`/user/gdpr/delete/${userId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders,
      method: 'DELETE'
    }).then(this.handleResponse);
  }

  public fetchLoggedInUser(): Promise<User | undefined> {
    const url: string = this.getUrl(`/currentUser`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data.id === 0) {
          return undefined;
        }
        return this.jsonConvert.deserialize(responseJson.data, User);
      });
  }

  public loginUser(idCard: string, password: string): Promise<void> {
    const url: string = this.getUrl('/login/auth');
    const body = new URLSearchParams();
    body.append('id_card', idCard);
    body.append('password', password);
    const defaultCopy = Object.assign({}, this.defaultHeaders);
    const headers = Object.assign(defaultCopy, {
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return fetch(url, {
      method: 'POST',
      credentials: this.credentials,
      headers: headers,
      body: body
    }).then(this.handleResponse);
  }

  public logoutCurrentUser(): Promise<void> {
    let url: string = this.getUrl(`/logout`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleNetworkErrors);
  }

  public updateUserPassword(userId: number, passwordA: string, passwordB: string): Promise<void> {
    const url: string = this.getUrl(`/user/passwords/change?userId=${userId}`);
    const body = {
      password_one: passwordA,
      password_two: passwordB
    };
    return fetch(url, {
      method: 'PUT',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(body)
    }).then(this.handleResponse);
  }

  public updateUserPasswordWithOldPassword(userId: number, oldPassword: string, passwordA: string, passwordB: string): Promise<void> {
    const url: string = this.getUrl(`/user/passwords/change?userId=${userId}&currentpassword=${oldPassword}`);
    const body = {
      password_one: passwordA,
      password_two: passwordB
    };
    return fetch(url, {
      method: 'PUT',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(body)
    }).then(this.handleResponse);
  }

  public forgotPassword(idCard: string, admin: boolean): Promise<void> {
    const url: string = this.getUrl(`/user/passwords/forgot?idCard=${idCard}&referer=${admin ? 'admin' : 'website'}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders,
      method: 'POST'
    }).then(this.handleResponse);
  }

  public resetPassword(token: string, passwordOne: string, passwordTwo: string, admin: boolean): Promise<void> {
    const url: string = this.getUrl(`/user/passwords/change?token=${token}&referer=${admin ? 'admin' : 'website'}`);
    const payload: any = {
      password_one: passwordOne,
      password_two: passwordTwo
    };
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders,
      method: 'PUT',
      body: JSON.stringify(payload)
    }).then(this.handleResponse);
  }
}

export default UserService;

import GenericStore from './GenericStore';

interface ILoggedError {
  error: Error;
  errorInfo: React.ErrorInfo;
  id: number;
}

export default class ErrorStore extends GenericStore<ILoggedError> {
  public logError(error: Error, errorInfo: React.ErrorInfo): void {
    const loggedError: ILoggedError = {
      error: error,
      errorInfo: errorInfo,
      id: Date.now()
    };
    this.add(loggedError);
  }

  public getLastError(): ILoggedError | undefined {
    if (this.contents.length === 0) {
      return undefined;
    }
    const lastLoggedError: ILoggedError = this.contents.map((e: ILoggedError) => e)[this.contents.length - 1];
    return lastLoggedError;
  }
}

export { ILoggedError };
